import { BetData, Country } from "@/types/betting";
import { create } from "zustand";

interface BettingState {
  // 基础游戏状态
  timeLeft: number;
  userBetId: number | null;
  userVegetable: number | null;
  betCount: number;
  pieData: BetData[];
  countrys: Country[];

  // UI状态
  isSheetOpen: boolean;
  isTransactionSheetOpen: boolean;
  isResultDrawerOpen: boolean;

  // 游戏结果状态
  gameResult: "win" | "lose" | null;
  winnerInfo: {
    winnerUsers: never[];
    winnerCountry: {
      countryId: null;
      totalAmount: number;
    };
  } | null;

  // 音乐状态
  currentMusic: "music1" | "music2" | null;

  // Actions
  setTimeLeft: (time: number) => void;
  setUserBetId: (id: number | null) => void;
  setUserVegetable: (id: number | null) => void;
  setBetCount: (count: number) => void;
  setPieData: (data: BetData[]) => void;
  setCountrys: (data: Country[]) => void;
  setIsSheetOpen: (isOpen: boolean) => void;
  setIsTransactionSheetOpen: (isOpen: boolean) => void;
  setIsResultDrawerOpen: (isOpen: boolean) => void;
  setGameResult: (result: "win" | "lose" | null) => void;
  setWinnerInfo: (info: BettingState["winnerInfo"]) => void;
  setCurrentMusic: (music: "music1" | "music2" | null) => void;
  resetGame: () => void;
}

const getInitialState = () => ({
  timeLeft: 60,
  userBetId: null,
  userVegetable: null,
  betCount: 10,
  pieData: [
    {
      id: "1",
      value: 20,
      color: "#4DFF67",
      bets: 0,
      name: "Chili",
      betRecords: [],
      position: "bottom-[40%] left-[18%]",
      totalAmount: 0,
    },
    {
      id: "2",
      value: 20,
      color: "#EF42FF",
      bets: 0,
      name: "Eggplant",
      betRecords: [],
      position: "bottom-[40%] right-[25%]",
      totalAmount: 0,
    },
    {
      id: "3",
      value: 20,
      color: "#FFEA00",
      bets: 0,
      name: "Bacon",
      betRecords: [],
      position: "top-[8%] left-1/2 -translate-x-1/2",
      totalAmount: 0,
    },
    {
      id: "4",
      value: 20,
      color: "#224EFF",
      bets: 0,
      name: "Sausage",
      betRecords: [],
      position: "top-[18%] left-[15%]",
      totalAmount: 0,
    },
    {
      id: "5",
      value: 20,
      color: "#FF3E3E",
      bets: 0,
      name: "Mushroom",
      betRecords: [],
      position: "top-[18%] right-[15%]",
      totalAmount: 0,
    },
  ],
  countrys: [],
  isSheetOpen: false,
  isTransactionSheetOpen: false,
  isResultDrawerOpen: false,
  gameResult: null,
  winnerInfo: {
    winnerUsers: [],
    winnerCountry: {
      countryId: null,
      totalAmount:0
    },
  },
  currentMusic: null,
});

export const useBettingStore = create<BettingState>((set) => ({
  ...getInitialState(),
  setTimeLeft: (time) => set({ timeLeft: time }),
  setUserBetId: (id) => set({ userBetId: id }),
  setUserVegetable: (id) => set({ userVegetable: id }),
  setBetCount: (count) => set({ betCount: count }),
  setPieData: (data) => set({ pieData: data }),
  setCountrys: (data) => set({ countrys: data }),
  setIsSheetOpen: (isOpen) => set({ isSheetOpen: isOpen }),
  setIsTransactionSheetOpen: (isOpen) =>
    set({ isTransactionSheetOpen: isOpen }),
  setIsResultDrawerOpen: (isOpen) => set({ isResultDrawerOpen: isOpen }),
  setGameResult: (result) => set({ gameResult: result }),
  setWinnerInfo: (info) => set({ winnerInfo: info }),
  setCurrentMusic: (music) => set({ currentMusic: music }),

  // Reset game state
  resetGame: () => set(getInitialState()),
}));
