import { viewport } from "@telegram-apps/sdk-react";
import { useLocation } from "react-router-dom";
import { EXCLUDE_SCROLL_ROUTES } from "../router/routes";

export const useViewport = () => {
  const location = useLocation();
  const shouldScroll = !(EXCLUDE_SCROLL_ROUTES as readonly string[]).includes(location.pathname);

  const getTopPadding = () => {
    if (!shouldScroll) return 0;
    return viewport.safeAreaInsetTop() + viewport.contentSafeAreaInsetTop();
  };

  return {
    topPadding: getTopPadding(),
    shouldScroll,
  };
}; 