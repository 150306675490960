import { ReactNode, createRef } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import './PageTransition.css';

// 定义路由配置，添加正确的类型
const routes = [
  { path: '/home', nodeRef: createRef<HTMLDivElement>() },
  { path: '/mining', nodeRef: createRef<HTMLDivElement>() },
  { path: '/betting', nodeRef: createRef<HTMLDivElement>() },
  { path: '/invite', nodeRef: createRef<HTMLDivElement>() },
  { path: '/task', nodeRef: createRef<HTMLDivElement>() },
  { path: '/rank', nodeRef: createRef<HTMLDivElement>() },
  { path: '/results', nodeRef: createRef<HTMLDivElement>() },
  { path: '/account', nodeRef: createRef<HTMLDivElement>() },
  { path: '/login', nodeRef: createRef<HTMLDivElement>() },
];

interface PageTransitionProps {
  children?: ReactNode;
}

const PageTransition = ({ children }: PageTransitionProps) => {
  const location = useLocation();
  const currentOutlet = useOutlet();
  const { nodeRef } = routes.find((route) => route.path === location.pathname) ?? 
    { nodeRef: createRef<HTMLDivElement>() };

  // 为登录页设置更长的过渡时间
  const transitionTimeout = location.pathname === '/login' ? 300 : 100;

  return (
    <div className="page-wrapper">
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={transitionTimeout}
          classNames={location.pathname === '/login' ? "page-login" : "page"}
          unmountOnExit
        >
          <div ref={nodeRef} className="page">
            {currentOutlet || children}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default PageTransition;
