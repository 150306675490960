// 导入图标
import backIcon from './back.svg';
import dataDisplay from './data_display.svg';
import lockEmpty from './lock_empty.png';
import miningAdd from './mining_add.svg';
import miningOne from './mining_one.png';
import miningThree from './mining_three.png';
import miningTwo from './mining_two.png';
import unlockEmpty from './unlock_empty.svg';
import lockEmptyOne from './lock_empty_one.svg';
import unlockEmptyAnimated from './unlock_empty_animated.svg';
import greenBox from './green_box.png';
import pinkBox from './pink_box.png';
import blackBg from './black_bg.png';
import buyBox from './buy_box.png';
import purchased from './purchased.png';
import unlockGreen from './unlock_green.png';
import unlockGrey from './unlock_grey.png';
import backPng from './back.png';
import miningTitle from './mining_title.png';
import storageBox from './storage_box.png';

export const icons = {
  backIcon,
  dataDisplay,
  lockEmpty,
  miningAdd,
  miningOne,
  miningThree,
  miningTwo,
  unlockEmpty,
  lockEmptyOne,
  unlockEmptyAnimated,
  greenBox,
  pinkBox,
  blackBg,
  buyBox,
  purchased,
  unlockGreen,
  unlockGrey,
  backPng,
  miningTitle,
  storageBox,
} as const;
