import bbq from './bbq.png';
import bbqWar from './bbqWar.png';
import order from './order.png';
import orderPending from './orderPending.png';
export const icons = {
  bbq,
  bbqWar,
  order,
  orderPending
};

