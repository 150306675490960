import { createRef, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

// 定义基础组件Props类型
export interface BaseComponentProps {
  className?: string;
}

// 定义路由配置的类型
export interface RouteConfig extends Omit<RouteObject, 'element'> {
  path: string;
  element: React.LazyExoticComponent<React.ComponentType<BaseComponentProps>>;
  nodeRef: React.RefObject<HTMLDivElement>;
}

// 懒加载路由组件
export const routes: RouteConfig[] = [
  {
    path: "/mining",
    element: lazy(() => import("../pages/Mining")),
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: "/home",
    element: lazy(() => import("../pages/Home")),
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: "/invite",
    element: lazy(() => import("../pages/Invite")),
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: "/task",
    element: lazy(() => import("../pages/Task")),
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: "/rank",
    element: lazy(() => import("../pages/Rank")),
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: "/betting",
    element: lazy(() => import("../pages/Betting")),
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: "/results",
    element: lazy(() => import("../pages/Results")),
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: "/wallet",
    element: lazy(() => import("../pages/Wallet")),
    nodeRef: createRef<HTMLDivElement>(),
  },
];

// 路由常量
export const EXCLUDE_NAV_ROUTES = ["/mining", "/betting", "/login"] as const;
export const EXCLUDE_SCROLL_ROUTES = ["/home"] as const; 