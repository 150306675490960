import { authApi } from "@/api/auth";
import { bettingApi } from "@/api/betting";
import { useUserStore } from "@/store/user";
import { UserInfo } from "@/types/auth";
import { SpinePlayer } from "@esotericsoftware/spine-player";
import {
  initDataAuthDate,
  retrieveLaunchParams,
} from "@telegram-apps/sdk-react";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import PageTransition from "../components/PageTransition";
import { preloader } from '../utils/preloadResources';
const mockUser = {
  isLink: true,
  hash: "d4a5de28de06a4c29c215e6fc38aec431943ff347664e4603d34955873e2f127",
  user: {
    id: 2200534386,
    first_name: "Free",
    last_name: "Love",
    username: "admin",
    language_code: "zh-hans",
    is_premium: true,
    allows_write_to_pm: true,
    ip_address: "1.0.0.0",
    photo_url: "https://t.me/i/userpic/320/coder0xh.jpg",
  },
  auth_date: "1733211184",
  chat_instance: "-631388935813614742",
  chat_type: "private",
  start_param: "O1LSJEP9",
  query_id: "1",
};
function Login() {
  const navigate = useNavigate();
  const setUserInfo = useUserStore((state) => state.setUserInfo);
  const { initData } = retrieveLaunchParams();
  const spineRef = useRef<SpinePlayer | null>(null);
  // @ts-expect-error 类型错误
  const { chatInstance, chatType, hash, isLink, queryId, startParam, user } =
    initData;
  useEffect(() => {
    authApi
      .login({
        auth_date: initDataAuthDate()?.toISOString() || "",
        chat_instance: Number(chatInstance) || 0,
        chat_type: chatType || "",
        hash: hash || "",
        isLink: isLink || false,
        query_id: queryId || "",
        start_param: startParam || "",
        user: {
          id: user!.id || 0,
          first_name: user!.firstName || "",
          last_name: user!.lastName || "",
          username: user!.username || "",
          language_code: user!.languageCode || "",
          is_premium: user!.isPremium || false,
          allows_write_to_pm: user!.allowsWriteToPm || false,
          ip_address: user!.ipAddress || "",
          photo_url: user!.photoUrl || "",
        },
      })
      .then(() => {
        bettingApi.getCountrys();
        return authApi.getUserInfo();
      })
      .then((res) => {
        setTimeout(() => {
          setUserInfo(res.data);
          navigate("/home");
        }, 2000);
      })
      .catch((err) => {
        console.log("login error",err);
        setUserInfo(mockUser as unknown as UserInfo);
        setTimeout(() => {
          navigate("/home");
        }, 2000);
      });
  }, []);

  useEffect(() => {
    if (!spineRef.current && preloader.hasResource('loading')) {
      spineRef.current = preloader.initSpinePlayer('loading', 'spine-player-container');
    }

    return () => {
      if (spineRef.current) {
        preloader.disposeSpinePlayer('loading');
        spineRef.current = null;
      }
    };
  }, []);
  return (
    <PageTransition>
      <div className="flex flex-col w-full h-screen pt-[25%] items-center  min-h-screen ">
        <div
          id="spine-player-container"
          style={{
            width: "400px",
            height: "600px",
            marginBottom: "100px",
            position: "relative",
            background: "transparent",
            mixBlendMode: "normal",
          }}
        ></div>
      </div>
    </PageTransition>
  );
}

export default Login;
