import Bacon from "./Bacon.png";
import BaconLogo from "./BaconLogo.png";
import bettingBarbecuing from "./bettingBarbecuing.png";
import bettingBg from "./bettingBg.png";
import bettingContribute from "./bettingContribute.png";
import bettingStart from "./bettingStart.png";
import bettingStartBg from "./bettingStartBg.png";
import bettingStartLogo from "./bettingStartLogo.png";
import Chili from "./Chili.png";
import ChiliLogo from "./ChiliLogo.png";
import country from "./country.png";
import Eggplant from "./Eggplant.png";
import EggplantLogo from "./EggplantLogo.png";
import Mushroom from "./Mushroom.png";
import MushroomLogo from "./MushroomLogo.png";
import Sausage from "./Sausage.png";
import SausageLogo from "./SausageLogo.png";
import timeLogo from "./timeLogo.png";

export const bettingImages = {
  bettingStartLogo,
  bettingBarbecuing,
  timeLogo,
  country,
  bettingStart,
  bettingContribute,
  Chili,
  Bacon,
  Sausage,
  Mushroom,
  Eggplant,
  ChiliLogo,
  BaconLogo,
  EggplantLogo,
  MushroomLogo,
  SausageLogo,
  bettingBg,
  bettingStartBg,
};
