import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import { Background } from "./components/Background";
import { ErrorBoundary } from "./components/ErrorBoundary";
import FloatingNav from "./components/navigation/FloatingNav";
import PageTransition from "./components/PageTransition";
import Login from "./pages/Login";
import { EXCLUDE_NAV_ROUTES, routes } from "./router/routes";
import { useUserStore } from "./store/user";
import { preloader } from "./utils/preloadResources";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

function AnimatedRoutes() {
  const location = useLocation();
  const { userInfo } = useUserStore();
  const { t } = useTranslation();
  const isLoginPage = location.pathname === "/login" || location.pathname === "/";

  return (
    <PageTransition>
      <ErrorBoundary>
        <Suspense fallback={<div className="w-full h-full flex items-center justify-center">{t("common.loading")}</div>}>
          <Routes location={location}>
            {!userInfo || isLoginPage ? (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/*" element={<Navigate to="/login" replace />} />
              </>
            ) : (
              <>
                {routes.map(({ path, element: Element }) => (
                  <Route
                    key={path}
                    path={path}
                    element={<Element className="page-content" />}
                  />
                ))}
                <Route path="/*" element={<Navigate to="/home" replace />} />
              </>
            )}
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </PageTransition>
  );
}

function AppContent() {
  const location = useLocation();
  const shouldHideNav = EXCLUDE_NAV_ROUTES.includes(location.pathname as typeof EXCLUDE_NAV_ROUTES[number]);

  return (
    <Background>
      <div className="h-screen w-[100vw]">
        <AnimatedRoutes />
      </div>

      {!shouldHideNav && (
        <div className="fixed bottom-0 left-0 right-0 z-50">
          <div className="h-px bg-gradient-to-r from-anime-primary/20 via-anime-accent/20 to-anime-secondary/20" />
          <div className="bg-anime-bg-nav/95 backdrop-blur-xl">
            <FloatingNav />
          </div>
          <div className="absolute -bottom-4 left-1/2 -translate-x-1/2 w-3/4 h-8 bg-anime-primary/10 blur-xl rounded-full" />
        </div>
      )}
    </Background>
  );
}

function App() {
  const manifestUrl = import.meta.env.VITE_TON_CONNECT_MANIFEST_URL ?? 'https://bbq.meme/tonconnect-manifest.json';

  useEffect(() => {
    preloader.preloadAll();
  }, []);

  return (
    <TonConnectUIProvider manifestUrl={manifestUrl}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AppContent />
        </Router>
      </QueryClientProvider>
    </TonConnectUIProvider>
  );
}

export default App;
