import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig
} from "axios";
import { toast } from "react-toastify";

// 添加通用响应接口
interface ApiResponse<T> {
  code: number;
  data: T;
  message: string;
}

// 创建axios实例
const instance: AxiosInstance = axios.create({
  baseURL: "/api",
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
 
});

// 请求拦截器
instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${token}`;
    }
    console.log({config});
    // toast("发起了请求");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log({error});
    if (error.response) {
      switch (error.status) {
        case 401:
        //   localStorage.removeItem("token");
        //   window.location.href = "/login";
          break;
        case 403:
          console.error("没有权限访问该资源");
          break;
        case 404:
          console.error("请求的资源不存在");
          break;
        case 500:
          console.error("服务器错误");
          break;
        case 501:
          toast.error(error.response.data.error);
          console.error("未实现");
          break;
        default:
          console.error(`未知错误: ${error.message}`);
      }
    } else if (error.request) {
      console.error("网络错误，请检查您的网络连接");
    } else {
      console.error(`请求配置错误: ${error.message}`);
    }
    
    return Promise.reject(error);
  }
);

// 定义请求方法的泛型接口
export const request = {
  get<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return instance.get(url, config);
  },

  post<T = unknown, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig
  ): Promise<ApiResponse<T>> {
    return instance.post(url, data, config);
  },

  put<T = unknown, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig
  ): Promise<ApiResponse<T>> {
    return instance.put(url, data, config);
  },

  delete<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return instance.delete(url, config);
  },
};

export default instance;
