
import background from './background.png';
import background2 from './background2.png';
import { bettingImages } from './betting';
import bottomNavBg from './bottomNavBg.png';
import { homeImages } from './home';
import { loginImages } from './login';
const images = {
    background,
    background2,
    ...loginImages,
    ...homeImages,
    ...bettingImages,
    bottomNavBg,
};

export type ImageName = keyof typeof images;



const getImageByName = (
    imageName: ImageName
):string => images[imageName];

export default getImageByName;
