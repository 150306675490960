import game from "./game.png";
import invite from "./invite.png";
import rank from "./rank.png";
import task from "./task.png";
import wallet from "./wallet.png";

export const navIcons = {
  task,
  game,
  rank,
  wallet,
  invite,
};
