import footer from './footer.png';
import hand from './hand.png';
import headerBg from './headerBg.png';
import leftButton from './leftButton.png';
import leftLogo from './leftLogo.png';
import leftText from './leftText.png';
import leftTrapezoid from './leftTrapezoid.png';
import rightButton from './rightButton.png';
import rightLogo from './rightLogo.png';
import rightText from './rightText.png';
import rightTrapezoid from './rightTrapezoid.png';
import setting from './setting.png';
import title from './title.png';
export const homeImages = {
    leftTrapezoid,
    rightTrapezoid,
    leftLogo,
    rightLogo,
    leftButton,
    rightButton,
    leftText,
    rightText,
    title,
    headerBg,
    hand,
    footer,
    setting
};

