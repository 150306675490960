import { SpinePlayer } from "@esotericsoftware/spine-player";

interface SpineResource {
  jsonUrl: string;
  atlasUrl: string;
  binaryUrl: string;
}

const spineResources: Record<string, SpineResource> = {
  loading: {
    jsonUrl: '/spine/loading/fire.json',
    atlasUrl: '/spine/loading/fire.atlas',
    binaryUrl: '/spine/loading/fire.png'
  },
  home: {
    jsonUrl: '/spine/fire/fire.json',
    atlasUrl: '/spine/fire/fire.atlas',
    binaryUrl: '/spine/fire/fire.png'
  }
};

class ResourcePreloader {
  private static instance: ResourcePreloader;
  private loadedResources: Map<string, ArrayBuffer[]> = new Map();
  private spineInstances: Map<string, SpinePlayer> = new Map();
  private loading: boolean = false;

  private constructor() {}

  static getInstance(): ResourcePreloader {
    if (!ResourcePreloader.instance) {
      ResourcePreloader.instance = new ResourcePreloader();
    }
    return ResourcePreloader.instance;
  }

  async preloadAll(): Promise<void> {
    if (this.loading) return;
    this.loading = true;

    try {
      const loadPromises = Object.entries(spineResources).map(async ([key, resources]) => {
        const responses = await Promise.all([
          fetch(resources.jsonUrl),
          fetch(resources.atlasUrl),
          fetch(resources.binaryUrl)
        ]);

        const buffers = await Promise.all(
          responses.map(response => response.arrayBuffer())
        );

        this.loadedResources.set(key, buffers);
      });

      await Promise.all(loadPromises);
      console.log('All spine resources preloaded successfully');
    } catch (error) {
      console.error('Failed to preload resources:', error);
    } finally {
      this.loading = false;
    }
  }

  initSpinePlayer(key: string, containerId: string): SpinePlayer | null {
    const resources = spineResources[key];
    if (!resources) return null;

    // 如果已经有实例了，先销毁
    this.disposeSpinePlayer(key);

    try {
      const player = new SpinePlayer(containerId, {
        jsonUrl: resources.jsonUrl,
        atlasUrl: resources.atlasUrl,
        binaryUrl: resources.binaryUrl,
        animation: "animation",
        backgroundColor: "#00000000",
        alpha: true,
        premultipliedAlpha: false,
        showControls: false,
        scale: 1,
        preserveDrawingBuffer: false,
        success: () => {
          console.log(`Spine player ${key} loaded successfully`);
        },
        error: (_player: SpinePlayer, msg: string) => {
          console.error(`Error loading spine player ${key}:`, msg);
        }
      });

      this.spineInstances.set(key, player);
      return player;
    } catch (error) {
      console.error(`Failed to initialize spine player ${key}:`, error);
      return null;
    }
  }

  disposeSpinePlayer(key: string) {
    const player = this.spineInstances.get(key);
    if (player) {
      player.dispose();
      this.spineInstances.delete(key);
    }
  }

  hasResource(key: string): boolean {
    return this.loadedResources.has(key);
  }
}

export const preloader = ResourcePreloader.getInstance(); 