import { bettingIcons } from './betting';
import { icons as common } from './common';
import { icons as invite } from './invite';
import { navIcons } from './nav';
import { rankIcons } from './rank';
import { icons as mining } from './mining';

export const icons = {
    ...invite,
    ...common,
    ...bettingIcons,
    ...mining,
    ...navIcons,
    ...rankIcons,
};

export type IconName = keyof typeof icons;

export const getIconByName = (
    iconName: IconName
): string => icons[iconName];

export default getIconByName;
