import { useBettingStore } from "@/store/betting";
import axios from "@/utils/axios";

export const bettingApi = {
  getCountrys: () => {
    return axios.get("/user/game-war/countries").then((res) => {
      console.log();
      useBettingStore.getState().setCountrys(res.data);
      return res.data;
    });
  },
  getBettingVoteInfo: (roundId: string) => {
    return axios.get(`/user/game-war/vote/${roundId}`).then((res) => {
      return res.data;
    });
  },
  commitVote: (voteAmount: number) => {
    return axios.post(`/user/game-war/vote/commit`, { voteAmount })
  },
  getBbqAccount: () => {
    return axios.get(`/user/game-war/account`);
  },
};
