// 导入所需的库和类型
import getIconByName, { IconName } from "@/assets/icons";
import { hapticFeedback } from "@telegram-apps/sdk-react"; // 导入触觉反馈功能
import { motion } from "framer-motion"; // 导入动画库
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"; // 导入路由链接组件
import { useSound } from "@/hooks/useSound";
// 定义导航按钮的属性接口
interface NavButtonProps {
  to: string; // 路由路径
  icon: IconName; // 图标类型
  label: string; // 按钮标签
  isActive?: boolean; // 是否为活动状态
}

// 特殊导航按钮组件
export const SpecialNavButton = ({ to, icon, label }: NavButtonProps) => {
  console.log({ icon });
  const {t} = useTranslation();
  const { playBeep } = useSound();
  return (
    <Link
      to={to} // 路由链接
      className="relative  flex "  // CSS类
      onClick={() => {
        playBeep();
        hapticFeedback.impactOccurred("medium"); // 点击时触发触觉反馈
      }}
    >
      <motion.div
        className="flex flex-col items-center justify-center w-[68px] h-[68px] bg-gradient-to-br from-[#FF339F] via-[#FF0099] to-[#CC33FF] rounded-full border border-[#FF339F]/40 relative overflow-hidden" // 更粉的渐变色
        whileHover={{
          scale: 1.05,
          boxShadow: "0 0 40px rgba(255,51,159,0.6), inset 0 0 20px rgba(255,0,153,0.5)", // 粉色霓虹发光
        }}
        whileTap={{ scale: 0.90 }}
      >
        <div className="absolute inset-0 bg-gradient-to-br from-[#FF339F]/20 via-[#FF0099]/20 to-[#CC33FF]/20 backdrop-blur-sm"></div>
        <img src={getIconByName(icon)} alt={label} className="w-[24px] h-[24px] text-xl text-white drop-shadow-[0_0_12px_rgba(255,51,159,0.8)] relative z-10" />
        <span className="text-xs font-medium text-white mt-0.5 drop-shadow-[0_0_12px_rgba(255,51,159,0.8)] relative z-10">
        {t(`nav_${label}`)}
        </span>
        <motion.div
          className="absolute inset-0 bg-gradient-to-br from-[#FF339F] via-[#FF0099] to-[#CC33FF] rounded-full opacity-40" // 动画背景
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.2, 0.15, 0.2],
          }}
          transition={{
            duration: 2.5,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        />
      </motion.div>
    </Link>
  );
};

// 常规导航按钮组件
export const RegularNavButton = ({
  to,
  icon,
  label,
  isActive,
}: NavButtonProps) => {
  const {t} = useTranslation();
  const { playBeep } = useSound();
  return (
    <Link
      to={to} // 路由链接
      className="flex flex-col items-center justify-center gap-1 h-[40px] w-[72px]" // CSS类
      onClick={() => {
        playBeep();
        hapticFeedback.impactOccurred("medium"); // 点击时触发触觉反馈
      }}
    >
      <motion.div
        whileTap={{ scale: 0.9 }} // 点击时缩小
        className={`relative  h-[20px] w-[20px] flex items-center justify-center rounded-full transition-all duration-300 group ${isActive ? "text-white" : "text-gray-400 hover:text-gray-200" // 根据活动状态设置颜色
          }`}
      >
        <img src={getIconByName(icon)} alt={label} className={`text-xl relative z-10 ${isActive ? "opacity-100" : "opacity-50"}`} />
      </motion.div>
      <span
        className={`text-xs font-medium transition-colors duration-300 ${isActive ? "text-white" : "text-gray-400" // 根据活动状态设置颜色
          } text-center whitespace-nowrap`}
      >
        {t(`nav_${label}`)}
      </span>
    </Link>
  );
};
