import { IconName } from "@/assets/icons";
import getImageByName from "@/assets/images";
import { viewport } from "@telegram-apps/sdk-react";
import { IconType } from "react-icons";
import { BiSolidJoystick } from "react-icons/bi";
import { FaGift } from "react-icons/fa";
import { GiMining, GiTrophyCup } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { RegularNavButton, SpecialNavButton } from "./NavButton";

interface NavItem {
  icon: IconType;
  label: string;
  to: string;
  isSpecial?: boolean;
}

const navItems: NavItem[] = [
  {
    icon: HiUserGroup,
    label: "INVITE",
    to: "/invite",
  },
  {
    icon: BiSolidJoystick,
    label: "TASK",
    to: "/task",
  },
  {
    icon: GiMining,
    label: "GAME",
    to: "/home",
    isSpecial: true,
  },
  {
    icon: GiTrophyCup,
    label: "RANK",
    to: "/rank",
  },
  {
    icon: FaGift,
    label: "WALLET",
    to: "/wallet",
  },
];

const FloatingNav = () => {
  const location = useLocation();

  
  return (
    <div className="fixed   inset-x-0 mx-auto max-w-md z-50" style={{
      bottom: viewport.safeAreaInsets().bottom>40? 20:5
    }}>
      <div className="relative flex items-center justify-center h-full py-2 px-4 rounded-full">
        <div className="w-full flex justify-center     absolute top-0 left-0 px-4">
          <img
            className="w-full h-[72px] object-fill"
            src={getImageByName("bottomNavBg")}
            alt="bottomNavBg"
          />
        
        </div>
        <div className="h-[72px] -top-2  relative w-full px-4 flex items-center justify-between z-10">
          {navItems.map((item) => {
            const isActive = location.pathname === item.to;

            return item.isSpecial ? (
              <SpecialNavButton
                key={item.to}
                to={item.to}
                icon={item.label.toLowerCase() as IconName}
                label={item.label}
              />
            ) : (
              <RegularNavButton
                key={item.to}
                to={item.to}
                icon={item.label.toLowerCase() as IconName}
                label={item.label}
                isActive={isActive}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FloatingNav;
