import { ReactNode } from "react";
import getImageByName from "../assets/images";
import { useViewport } from "../hooks/useViewport";

interface BackgroundProps {
  children: ReactNode;
}

export const Background = ({ children }: BackgroundProps) => {
  const { topPadding } = useViewport();

  return (
    <div
      style={{
        backgroundImage: `url(${getImageByName("background")})`,
        backgroundSize: "cover",
        backgroundPosition: "100%,80%",
        paddingTop: `${topPadding}px`,
      }}
      className="relative box-border h-screen overflow-hidden"
    >
      <div
        style={{
          backgroundImage: `url(${getImageByName("background2")})`,
          backgroundSize: "100%,80%",
          backgroundPosition: "100%,80%",
          backgroundRepeat: "no-repeat",
        }}
        className="absolute inset-0"
      />
      <div className="h-screen relative">
        {children}
      </div>
    </div>
  );
}; 