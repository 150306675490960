import { LoginRequest, UserInfo } from "@/types/auth";
import request from "@/utils/axios";

export const authApi = {
  login: (data: LoginRequest) => {
    return request.post("/login/telegram", data);
  },
  
  getUserInfo: () => {
    return request.get<UserInfo>("/user/me");
  }
};
