import { UserInfo } from '@/types/auth'
import { create } from 'zustand'

interface UserState {
  userInfo: UserInfo | null
  setUserInfo: (user: UserInfo) => void
  clearUserInfo: () => void
}

// 从 localStorage 获取初始状态
const getInitialState = () => {
  const savedUser = localStorage.getItem('userInfo')
  return savedUser ? JSON.parse(savedUser) : null
}

export const useUserStore = create<UserState>((set) => ({
  userInfo: getInitialState(),
  setUserInfo: (user) => {
    localStorage.setItem('userInfo', JSON.stringify(user))
    set({ userInfo: user })
  },
  clearUserInfo: () => {
    localStorage.removeItem('userInfo')
    set({ userInfo: null })
  },
}))
