import { useEffect, useRef } from 'react';
import beepSound from '@/assets/audio/beep.mp3';
import inactiveButtonSound from '@/assets/audio/Inactive_Button.mp3';

export const useSound = () => {
  const audioContextRef = useRef<AudioContext | null>(null);
  const audioBufferRef = useRef<AudioBuffer | null>(null);
  const inactiveButtonBufferRef = useRef<AudioBuffer | null>(null);

  useEffect(() => {
    // 创建 AudioContext
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    audioContextRef.current = audioContext;

    // 加载并解码音频文件
    fetch(beepSound)
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
      .then(audioBuffer => {
        audioBufferRef.current = audioBuffer;
      })
      .catch(error => {
        console.error('Error loading audio:', error);
      });

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (!audioContextRef.current) return;

    fetch(inactiveButtonSound)
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => audioContextRef.current!.decodeAudioData(arrayBuffer))
      .then(audioBuffer => {
        inactiveButtonBufferRef.current = audioBuffer;
      })
      .catch(error => {
        console.error('Error loading audio:', error);
      });
  }, []);

  const playInactiveButton = () => {
    if (!audioContextRef.current || !inactiveButtonBufferRef.current) return;
    const source = audioContextRef.current.createBufferSource();
    source.buffer = inactiveButtonBufferRef.current;
    source.connect(audioContextRef.current.destination);
    const gainNode = audioContextRef.current.createGain();
    gainNode.gain.value = 0.5; // 设置音量为 50%
    source.connect(gainNode);
    gainNode.connect(audioContextRef.current.destination);
    source.start(0);
  };

  const playBeep = () => {
    if (!audioContextRef.current || !audioBufferRef.current) return;

    // 创建音频源
    const source = audioContextRef.current.createBufferSource();
    source.buffer = audioBufferRef.current;

    // 创建增益节点来控制音量
    const gainNode = audioContextRef.current.createGain();
    gainNode.gain.value = 0.5; // 设置音量为 50%

    // 连接节点
    source.connect(gainNode);
    gainNode.connect(audioContextRef.current.destination);

    // 立即播放
    source.start(0);
  };

  return { playBeep, playInactiveButton };
}; 