import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./index.css";
import "./utils/i18n";
import { init } from "./utils/init";

// 自定义toast样式
const toastStyle = {
  width: "80%",
  minHeight: "40px",
  margin: "0 auto",
  borderRadius: "10px",
  zIndex: 9999,
};

// 创建 Toast 容器
const toastRoot = document.createElement("div");
toastRoot.id = "toast-root";
document.body.appendChild(toastRoot);
ReactDOM.createRoot(toastRoot).render(
  <ToastContainer
    position="top-center"
    autoClose={false}
    hideProgressBar={false}
    newestOnTop={true}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      top: 100,
      width: "100%",
      maxWidth: "100%",
      zIndex: 9999,
    }}
    toastStyle={toastStyle}
  />
);

const root = ReactDOM.createRoot(document.getElementById("root")!);
init(true);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
