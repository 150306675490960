import {
  $debug,
  backButton,
  closingBehavior,
  init as initSDK,
  miniApp,
  restoreInitData,
  swipeBehavior,
  themeParams,
  viewport,
} from "@telegram-apps/sdk-react";

// 初始化函数，接受一个布尔值参数用于调试
export const init = async (debug: boolean): Promise<void> => {
  // 设置调试模式
  $debug.set(debug);
  // 初始化SDK
  initSDK();
  backButton.mount();
  backButton.hide();
  // 检查后退按钮和迷你应用是否被支持
  if (!backButton.isSupported() || !miniApp.isSupported()) {
    throw new Error("ERR_NOT_SUPPORTED");
  }

  // 挂载后退按钮、迷你应用和主题参数
  backButton.mount();
  miniApp.mount();
  themeParams.mount();

  // 挂载关闭行为并启用确认
  closingBehavior.mount();
  closingBehavior.enableConfirmation();

  // 挂载滑动行为并禁用垂直滑动
  swipeBehavior.mount();
  swipeBehavior.disableVertical();
  restoreInitData();
  // 挂载视口并处理错误
  await viewport.mount();
  viewport.bindCssVars();
  viewport.requestFullscreen();
  viewport.expand();
  viewport.safeAreaInsets();
  viewport.contentSafeAreaInsets();
  viewport.safeAreaInsetTop();
  viewport.safeAreaInsetBottom();
  viewport.safeAreaInsetLeft();
  viewport.safeAreaInsetRight();
  viewport.contentSafeAreaInsetTop();
  viewport.contentSafeAreaInsetBottom();
  viewport.contentSafeAreaInsetLeft();
  viewport.contentSafeAreaInsetRight();
  // 定义与组件相关的CSS变量
  miniApp.setBackgroundColor("#1A1B2E");
  miniApp.setHeaderColor("#1A1B2E");
  themeParams.bindCssVars();
};
