import furnace from './furnace.png';
import gift from './gift.webp';
import inviteAvatar1 from './inviteAvatar1.png';
import inviteAvatar2 from './inviteAvatar2.png';
import inviteAvatar3 from './inviteAvatar3.png';
import inviteAvatar4 from './inviteAvatar4.png';
import inviteAvatarGroup from './inviteAvatarGroup.png';
export const icons = {
  gift,
  furnace,
  inviteAvatar1,
  inviteAvatar2,
  inviteAvatar3,
  inviteAvatar4,
  inviteAvatarGroup,
};

