import loginBg from './loginBg.svg';
import loginBg2 from './loginBg2.png';
import loginLogo from './loginLogo.png';

export const loginImages = {
    loginBg,
    loginLogo,
    loginBg2,
};

